// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import baseUserContextPage from "./baseUserContextPage";
import { useFaq } from '@/composables';
import { onBeforeMount } from "@vue/composition-api";

@Component({
  setup(props, ctx) {
    const { faq, getFaq } = useFaq();

    onBeforeMount(() => {
      useFaq();
    })

    return { faq };
  }
})
export default class faqPage extends baseUserContextPage {
  showContent: boolean = false;
  created() {
    var self = this;

    setTimeout(() => {
      self.showContent = true;

    }, 1);
  }
}
